import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ContentPadding from '../components/ContentPadding'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

export default class ShowsPage extends React.Component {
  constructor(props) {
    super(props);
    
    const { data } = props;
    
    const shows = data.shows.edges.map(({ node: show }) => show);
    
    this.state = {shows: shows};
  }

  render() {
    return (
      <Layout>
        <Helmet title="Voices Across Time | Shows" />
        <ContentPadding>
          <section className="section">
            <h1 className="has-text-weight-bold is-size-2">Shows</h1>
            <div className="columns is-centered is-multiline is-size-5 is-variable">
                {this.state.shows
                  .map((show, i) => (
                    <div className="column is-one-quarter" key={show + show.id}>
                        <Link className="show-shadow-image" to={show.fields.slug}>
                            <Img fluid={show.frontmatter.small_image.childImageSharp.fluid} />
                            <div className="content show-overlay has-text-primary has-text-centered">
                                <div className="show-title has-text-weight-bold">{show.frontmatter.title}</div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
          </section>
        </ContentPadding>
      </Layout>
    )
  }
}

export const showsQuery = graphql`
  query ShowsQuery {
    shows: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "show" }, published: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            small_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 565, quality: 75, cropFocus: CENTER, toFormat: JPG) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
